import React from 'react';
import { Layout } from 'components';
import TopOfMind from 'components/Sections/TopOfMind';
import GaugeCharts from 'components/Sections/GaugeCharts';
import InfoTables from '../../components/Sections/InfoTablesUpdated';
import CardLine from 'components/Charts/Cards/CardLine.js';

const EmpowerIDStatOrganizationID_657 = {
  TypeName: 'EmpowerIDStatOrganizationView',
  MethodName: 'GetByEmpowerIDStatID',
  IncludedProperties: [
    'TotalStat',
    'FriendlyName',
    'EmpowerIDStatOrganizationID',
  ],
  Parameters: {
    empowerIDStatID: 657,
    start: 0,
    pageLength: 500,
    totalCount: -1,
  },
};

const EmpowerIDStatOrganizationID_650 = {
  TypeName: 'EmpowerIDStatOrganizationView',
  MethodName: 'GetByEmpowerIDStatID',
  IncludedProperties: [
    'TotalStat',
    'FriendlyName',
    'EmpowerIDStatOrganizationID',
  ],
  Parameters: {
    empowerIDStatID: 650,
    start: 0,
    pageLength: 500,
    totalCount: -1,
  },
};

const ADA = () => {
  return (
    <Layout>
      <h1>Top of Mind</h1>
      <TopOfMind />

      <h1>Percent Optimized</h1>
      <GaugeCharts />

      <h1>Timeline</h1>
      <CardLine
        chartHeight={'400px'}
        title="Progression Towards Just In Time Infrastructure"
        key="91"
        child={EmpowerIDStatOrganizationID_650}
        convertYtoPercentage={true}
      />

      <h1>Timeline </h1>

      <CardLine
        chartHeight={'400px'}
        title="Person Risk Scores"
        key="57"
        child={EmpowerIDStatOrganizationID_657}
      />

      <h1>Top 10</h1>
      <InfoTables />
    </Layout>
  );
};

export default ADA;
