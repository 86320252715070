import React, { Fragment } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  Button,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { Loader } from 'packages/eid-ui';
import { Icon } from 'packages/eid-icons';
import { PersonDetails } from '../PersonAvatar';

const useTableStyles = makeStyles({
  root: {
    backgroundColor: '#f7f8fa',
    marginBottom: '10px',
    minWidth: '100%',
    padding: '10px',
    '& table': {
      borderSpacing: '0px 5px',
      borderCollapse: 'separate',
      maxHeight: 200,
    },
    '& button': {
      background: '#e0ecf6',
      color: '#2c82be',
      border: '0px',
      '&:hover': {
        background: '#e0ecf6',
        border: '1px solid',
      },
    },
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },
  tableCell: {
    padding: '0px',
    margin: '0px',
    border: '0px',
  },
});

const useRowStyles = makeStyles({
  root: {
    width: '100%',
    height: '80px',
    backgroundColor: '#fff',
    padding: '0px',
    '&:hover': {
      backgroundImage: 'linear-gradient(to right, #469ccf, #307fc1)',
      cursor: 'pointer',
      '& p': {
        color: 'white',
        alignContent: 'center',
      },
      '& .tags': {
        background: 'transparent',
        border: '1px solid white',
        '& svg': {
          color: 'white',
          fill: 'white',
        },
      },
    },
  },
});

const Top10Table = ({ header, tableData }) => {
  const tableClasses = useTableStyles();
  const rowClasses = useRowStyles();

  return (
    <TableContainer classes={tableClasses}>
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: '24px', marginLeft: '20px', fontWeight: 'bold' }}>
          {header.title}
        </p>
        {header.button && (
          <Button
            variant="outlined"
            onClick={() => {
              window.open(header.button.url, '_blank');
            }}
          >
            {header.button.label}
          </Button>
        )}
      </span>

      <Table>
        <TableBody>
          {!tableData && (
            <TableRow>
              <TableCell />
              <Loader />
              <TableCell />
            </TableRow>
          )}
          {tableData &&
            tableData.map((row, index) => {
              return (
                <TableRow classes={rowClasses} onClick={() => { row.rowUrl && window.open(row.rowUrl, '_blank') }}>
                  <TableCell
                    width="5%"
                    key={`index-${index}`}
                    style={{
                      color: 'rgba(232, 233, 238, 0.6)',
                      fontSize: '45px',
                      padding: '0px',
                      textAlign: 'center',
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell key={`avatar-${index}`} style={{ padding: '0px' }}>
                    <PersonDetails
                      avatarSize="large"
                      person={{
                        personGuid: row.personGuid,
                        friendlyName: row.name,
                        email: row.email,
                        telephone: row.phone,
                        title: row.title,
                        company: row.company,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    width="5%"
                    key={`value-${index}`}
                    style={{
                      fontWeight: 'bold',
                      fontSize: '28px',
                      padding: '0px',
                      // margin: '0px',
                      float: 'end',
                    }}
                  >
                    <p style={{ margin: '10px' }}>{row.count}</p>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Top10Table;
