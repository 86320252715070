import React from 'react';
import { stat } from 'fs';
import useWindowSize from 'hooks/useWindowSize.js';
const SingleStat = (props) => {
  const windowSize = useWindowSize();
  let isMobile = windowSize.width < 600;
  const colors = [
    '#262fa1',
    '#9230c5',
    '#0078fa',
    '#ff6100',
    '#ba002c',
    '#ff007b',
    '#00a4d8',
    '#dc3131',
    '#00b83e',
    '#ffb800',
    '#265fb4',
    '#d50393',
    '#01c29f',
    '#ff6f57',
  ];
  let item = {
    flexGrow: 0,
  };
  let fontSize = '16px';
  if (windowSize.width < 1300) fontSize = '14px';
  if (isMobile) fontSize = '12px';

  let statNumberStyle = {
    ...item,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 20px 0px',
    fontSize: '1.7vmin',
  };
  let statTotalValueStyle = {
    fontFamily: 'Rubik',
    fontSize: isMobile ? '18px' : '22px',
    fontWeight: 600,
    color: '#000000',
  };
  let statDescriptionStyle = {
    display: 'block',
    marginTop: '-2px',
    fontSize: fontSize,
    width: '100%',
  };

  let statFlex = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height:
      windowSize.width < 1400 && windowSize.width > 1200 ? '115px' : '100px',
    width: isMobile ? '280px' : '100%',
    marginRight: isMobile ? '10px' : '0px',
    borderRadius: '10px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
  };
  let state = props.config.state,
    letterIcon = props.config.letterIcon,
    valueType = props.config.valueType;

  let setColor = valueType == 'Currency' ? '#00b83e' : '#0078fa';
  let statImageStyle = {
    ...item,
    padding: '20px',
    borderLeft: '3px solid ' + setColor,
    borderRadius: '5px',
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const valueTypeItems = {
    Integer: '',
    Currency: '$',
    Percentage: '%',
  };

  let formatTotalStat = (num) => {
    if (state.TotalStat != null) {
      let res = numberWithCommas(state.TotalStat);
      if (valueType == undefined) {
        res = res + valueTypeItems.Integer;
        return res;
      } else if (valueType == 'Currency') {
        res = valueTypeItems.Currency + res;
        return res;
      } else {
        res = res + valueTypeItems[valueType];
        return res;
      }
    } else return 0;
  };
  let totalStatNumberWithCommas = formatTotalStat(state.TotalStat);
  const LetterIcon = () => {
    return (
      <svg height="60" width="65">
        <defs>
          <filter id="f1" x="0" y="0" width="200%" height="200%">
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
          </filter>
        </defs>
        <circle
          cx="35"
          cy="30"
          r="30"
          stroke-width="3"
          fill={letterIcon == '$' ? '#00b83e' : '#22459c'}
        ></circle>
        <circle cx="35" cy="30" r="21" stroke-width="3" fill="#ffffff"></circle>
        <text
          x="55%"
          y="50%"
          text-anchor="middle"
          fill={letterIcon == '$' ? '#00b83e' : '#22459c'}
          dy=".3em"
          font-family="Rubik,sans-serif,Source Sans Pro,Muli,Roboto,Helvetica,Arial"
          font-size="2em"
          border-shadow="2px solid black"
          filter="url(#f1)"
        >
          {letterIcon}
        </text>
      </svg>
    );
  };
  return (
    <div style={statFlex}>
      <div style={statImageStyle}>
        <LetterIcon />
      </div>
      <div style={statNumberStyle}>
        <span style={statTotalValueStyle}>{totalStatNumberWithCommas}</span>
        <p style={statDescriptionStyle}>{state.FriendlyName}</p>
      </div>
    </div>
  );
};

export default SingleStat;
