import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { RiskAnalyticsIcon, Icon, EidLogoIcon } from 'packages/eid-icons';
import { Header, Dropdown } from 'packages/eid-ui';
import envConfig from 'config';
import { default as useUserManager } from 'packages/core/auth/useUserManager';
import AccountDropdown from './AccountDropdown';
import { Container } from '@material-ui/core';

const RiskAnalyticsHeader = ({ history }) => {
  const userManager = useUserManager();
  const [userId, setUserId] = useState();
  const [name, setName] = useState('');
  const imageUrl = `${envConfig.API_URL}/UI/Common/Person/Image?personGUID=${userId}&size=Thumb`;

  useEffect(() => {
    userManager.getUser().then((user) => {
      setUserId(user?.profile?.oid);
      setName(user?.profile?.name);
    });
  }, [userManager]);

  return (
    <Header>
      <Header.IconLink logo href="/" rightDivider>
        <EidLogoIcon />
      </Header.IconLink>
      <Header.IconLink logo href="/">
        <RiskAnalyticsIcon />
      </Header.IconLink>
      <Header.Title>Risk Analytics</Header.Title>

      <Header.RightSection>
        <AccountDropdown
          friendlyName={name}
          imageUrl={imageUrl}
          key={'account-dropdown'}
        />
      </Header.RightSection>
    </Header>
  );
};
export default withRouter(RiskAnalyticsHeader);
