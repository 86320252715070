import React from 'react';
import { Layout } from 'packages/eid-ui';
import RiskAnalyticsHeader from './RiskAnalyticsHeader';
import RiskAnalyticsGlobalMenu from './RiskAnalyticsGlobalMenu';

const RiskAnalyticsLayout = ({ children }) => {
  return (
    <Layout
      header={<RiskAnalyticsHeader />}
      globalMenu={
        <div style={{ marginTop: '24px' }}>
          <RiskAnalyticsGlobalMenu />
        </div>
      }
      children={<div>{children}</div>}
    />
  );
};

export default RiskAnalyticsLayout;
