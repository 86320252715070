import React, { useState, useEffect } from 'react';
import { GlobalMenu } from 'packages/eid-ui';
import axios from 'axios';
import useAxiosOptions from 'hooks/useAxiosOptions';
import envConfig from 'config';

const appNameMaps = {
  EIDEmpowerIDWeb: 'EmpowerID',
  EIDAzureAnalyticsMS: 'AzureAnalytics',
  EIDITShopMS: 'ITShop',
  EIDMyIdentity: 'MyIdentity',
  EIDMyTasks: 'MyTasks',
  EIDResourceAdminMS: 'ResourceAdmin',
  EIDRiskAnalyticsMS: 'RiskAnalytics',
};

const RiskAnalyticsGlobalMenu = () => {
  const [data, setData] = useState(null);
  const getAxiosOptions = useAxiosOptions();

  useEffect(() => {
    axios({
      ...getAxiosOptions(),
      method: 'GET',
      baseURL: `${envConfig.API_URL}`,
      url: 'api/services/v1/msvcs/common/applications/allowed-ssoapps',
    }).then((response) => {
      const responseApps = response.data.map((r) => ({
        id: r.ApplicationId,
        applicationName: r.ApplicationName,
        applicationDisplayName: r.ApplicationDisplayName,
        applicationAbsoluteUrl: r.ApplicationAbsoluteUrl,
      }));
      setData(responseApps);
    });
  }, []);

  const eidApp = data?.find((a) => a.applicationName === 'EIDEmpowerIDWeb');
  return (
    <GlobalMenu
      loading={!data}
      eid={
        eidApp
          ? {
              name: appNameMaps[eidApp.applicationName],
              displayName: eidApp.applicationDisplayName,
              url: eidApp.applicationAbsoluteUrl,
            }
          : undefined
      }
      apps={data
        ?.filter((f) => f.applicationName !== 'EIDEmpowerIDWeb')
        .map((a) => ({
          name: appNameMaps[a.applicationName],
          displayName: a.applicationDisplayName,
          url: a.applicationAbsoluteUrl,
        }))}
      current="RiskAnalytics"
    />
  );
};

export default RiskAnalyticsGlobalMenu;
