import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Gauge from '../Gauge.js';
import useAxiosOptions from 'hooks/useAxiosOptions';
import { Loader } from 'packages/eid-ui';

const CardGauge = (props) => {
  const getAxiosOptions = useAxiosOptions();
  let wrapperStyles = {
    ...props.style,
  };

  let styles = {
    height: '20em',
    paddingTop: '15px',
    width: '100%',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
  };

  let cardHeaderStyle = {
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: '#fbfbfd',
    padding: '25px',
  };

  let headerFontStyle = {
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  };

  let statRequestParams = {
    TypeName: 'EmpowerIDStatOrganizationView',
    MethodName: 'GetByEmpowerIDStatID',
    IncludedProperties: [
      'TotalStat',
      'FriendlyName',
      'EmpowerIDStatOrganizationID',
    ],
    Parameters: {
      empowerIDStatID: 0,
      start: 0,
      pageLength: 500,
      totalCount: -1,
    },
  };

  let config = props.config;
  statRequestParams.Parameters['empowerIDStatID'] = config.statID;
  const [state, setState] = useState([]);
  const [friendlyName, setFriendlyName] = useState('Default Stat');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let result = await axios({
          ...getAxiosOptions(),
          method: 'POST',
          url: `/${statRequestParams['TypeName']}/${statRequestParams['MethodName']}`,
          data: statRequestParams,
        });
        let response = result.data.Data;
        setFriendlyName(response.FriendlyName);
        setIsLoading(false);
        setDescription(response.Description);
        let totalStat = JSON.parse(response.TotalStat);
        setState(totalStat);
      } catch (ex) {
        setIsLoading(false);
        setFriendlyName('Failed to Load');
        throw 'Undesirable data \n' + ex.stack();
      }
    }
    fetchData();
  }, []);

  let resp = {
    state,
    friendlyName,
    description,
  };

  return (
    <div style={wrapperStyles}>
      <div style={cardHeaderStyle}>
        <i style={headerFontStyle}>{resp.friendlyName}</i>
        <i title={resp.description} style={{ float: 'right' }}>
          <svg
            fill="#000000"
            xmlns="http://www.w3.org/2000/svg"
            opacity="0.8"
            viewBox="0 0 32 32"
            width="25px"
            height="25px"
          >
            <path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z" />
          </svg>
        </i>
      </div>
      <div style={styles}>
        {isLoading ? <Loader /> : <Gauge config={resp} id={props.id} />}
      </div>
    </div>
  );
};

export default CardGauge;
