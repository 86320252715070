import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Risk from '../Risk';
import AppRoutes from 'containers/AppRoutes';

function App() {
  return (
    <Switch>
      <AppRoutes />
      <Route exact path="/" component={Risk} />
    </Switch>
  );
}

export default App;
