import React, { useEffect, useState } from 'react';
import InformationTableUpdated from '../Top10/TableComponent';
import useAxiosOptions from 'hooks/useAxiosOptions';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import config from 'config';

const TopPeopleByHighRiskMembership = () => {
  const getAxiosOptions = useAxiosOptions();
  const [tableData, setTableData] = useState();

  const highRiskMembershipRequestParams = {
    TypeName: 'PersonView',
    MethodName: 'GetTopPeopleByHighRiskMembership',
    IncludedProperties: [
      'FriendlyName',
      'TotalStats',
      'PersonGUID',
      'Email',
      'PersonalEmail',
      'Mobilephone',
      'Telephone',
      'Title',
      'Company',
    ],
    Parameters: {
      ResourceSystemID: null,
      ResourceSystemTypeID: null,
      top: 10,
      start: 0,
      pageLength: 500,
      totalCount: -1,
      textToSearch: null,
      columnsToSearch: 'FriendlyName,TotalStats',
    },
  };
  useEffect(() => {
    async function fetchTopPeopleByHighRiskMembership() {
      let result = await axios({
        ...getAxiosOptions(),
        method: 'POST',
        url: `/${highRiskMembershipRequestParams['TypeName']}/${highRiskMembershipRequestParams['MethodName']}`,
        data: highRiskMembershipRequestParams,
      });

      let response = result.data.Data;
      let data = [];
      response.forEach((element) => {
        data.push({
          name: element.FriendlyName,
          personGuid: element.PersonGUID,
          email: element.Email ? element.Email : element.PersonalEmail,
          phone: element.Telephone ? element.Telephone: element.Mobilephone,
          title: element.Title ? element.Title : 'Unknown',
          company: element.Company ? element.Company : 'Unknown',
          count: element.TotalStats,
        });
      });
      setTableData(data);
    }
    fetchTopPeopleByHighRiskMembership();
  }, []);

  return (
    <InformationTableUpdated
      header={{
        title: 'People By # Of High Risk Memberships',
        button: {
          label: 'Optimize Risk',
          url: `${config.API_URL}/ui/#w/OptimizeLeastPrivForPeople?popupNav=true`,
        },
      }}
      tableData={tableData}
      style={{ minWidth: '500px', marginLeft: '10px' }}
    />
  );
};

const TopPeopleByHighRiskFuntion = () => {
  const getAxiosOptions = useAxiosOptions();

  const [tableData, setTableData] = useState();

  const highRiskFuntionRequestParams = {
    TypeName: 'PersonView',
    MethodName: 'GetTopPeopleByHighRiskFunction',
    IncludedProperties: [
      'FriendlyName',
      'TotalStats',
      'PersonGUID',
      'Email',
      'PersonalEmail',
      'Mobilephone',
      'Telephone',
      'Title',
      'Company',
    ],
    Parameters: {
      ResourceSystemID: null,
      ResourceSystemTypeID: null,
      top: 10,
      start: 0,
      pageLength: 500,
      totalCount: -1,
      textToSearch: null,
      columnsToSearch: 'FriendlyName,TotalStats',
    },
  };

  useEffect(() => {
    async function fetchTopPeopleByHighRiskFunction() {
      let result = await axios({
        ...getAxiosOptions(),
        method: 'POST',
        url: `/${highRiskFuntionRequestParams['TypeName']}/${highRiskFuntionRequestParams['MethodName']}`,
        data: highRiskFuntionRequestParams,
      });

      let response = result.data.Data;
      let data = [];
      response.forEach((element) => {
        data.push({
          name: element.FriendlyName,
          personGuid: element.PersonGUID,
          email: element.Email ? element.Email : element.PersonalEmail,
          phone: element.Telephone ? element.Telephone: element.Mobilephone,
          title: element.Title ? element.Title : 'Unknown',
          company: element.Company ? element.Company : 'Unknown',
          count: element.TotalStats,
        });
      });
      setTableData(data);
    }
    fetchTopPeopleByHighRiskFunction();
  }, []);

  return (
    <InformationTableUpdated
      header={{
        title: 'People By # Of High Risk Functions',
        button: {
          label: 'Optimize Risk',
          url: `${config.API_URL}/ui/#w/OptimizeLeastPrivForPeople?popupNav=true`,
        },
      }}
      tableData={tableData}
      style={{ minWidth: '500px', marginRight: '10px' }}
    />
  );
};

const TopPeopleByHighAzLocalRisks = () => {
  const getAxiosOptions = useAxiosOptions();

  const [tableData, setTableData] = useState();

  const highAzLocalRisksRequestParams = {
    TypeName: 'PersonView',
    MethodName: 'GetTopPeopleByHighAzLocalRisks',
    IncludedProperties: [
      'FriendlyName',
      'TotalStats',
      'PersonGUID',
      'Email',
      'PersonalEmail',
      'Mobilephone',
      'Telephone',
      'Title',
      'Company',
      'ResourceID',
    ],
    Parameters: {
      ResourceSystemID: null,
      ResourceSystemTypeID: null,
      top: 10,
      start: 0,
      pageLength: 500,
      totalCount: -1,
      textToSearch: null,
      columnsToSearch: 'FriendlyName,TotalStats',
    },
  };

  useEffect(() => {
    async function fetchTopPeopleByHighRiskFunction() {
      let result = await axios({
        ...getAxiosOptions(),
        method: 'POST',
        url: `/${highAzLocalRisksRequestParams['TypeName']}/${highAzLocalRisksRequestParams['MethodName']}`,
        data: highAzLocalRisksRequestParams,
      });

      let response = result.data.Data;
      let data = [];
      response.forEach((element) => {
        data.push({
          name: element.FriendlyName,
          personGuid: element.PersonGUID,
          email: element.Email ? element.Email : element.PersonalEmail,
          phone: element.Telephone ? element.Telephone: element.Mobilephone,
          title: element.Title ? element.Title : 'Unknown',
          company: element.Company ? element.Company : 'Unknown',
          count: element.TotalStats,
          rowUrl: `${config.API_URL}/UI/#view/${element.ResourceID}`
        });
      });
      setTableData(data);
    }
    fetchTopPeopleByHighRiskFunction();
  }, []);

  return (
    <InformationTableUpdated
      header={{
        title: 'People By # Of Risk Violations',
      }}
      tableData={tableData}
      style={{ marginLeft: '10px', minWidth: '500px' }}
    />
  );
};

const TopRiskyAssignees = () => {
  const getAxiosOptions = useAxiosOptions();

  const [tableData, setTableData] = useState();

  const riskyAssigneeByMembersRequestParams = {
    TypeName: 'AssigneeView',
    MethodName: 'GetTopRiskAssigneeByNumberOfMembers',
    IncludedProperties: [
      'PreviewName',
      'TotalStats',
      'PersonGUID',
      'Email',
      'PersonalEmail',
      'Mobilephone',
      'Telephone',
      'Title',
      'Company',
      'AssigneeResourceID'
    ],
    Parameters: {
      ResourceSystemID: null,
      ResourceSystemTypeID: null,
      top: 10,
      start: 0,
      pageLength: 500,
      totalCount: -1,
      textToSearch: null,
      columnsToSearch: 'PreviewName,TotalStats',
    },
  };

  useEffect(() => {
    async function fetchTopRiskyAssigneeByMemberFunction() {
      let result = await axios({
        ...getAxiosOptions(),
        method: 'POST',
        url: `/${riskyAssigneeByMembersRequestParams['TypeName']}/${riskyAssigneeByMembersRequestParams['MethodName']}`,
        data: riskyAssigneeByMembersRequestParams,
      });

      let response = result.data.Data;
      let data = [];
      response.forEach((element) => {
        data.push({
          name: element.PreviewName,
          personGuid: element.PersonGUID,
          email: element.Email ? element.Email : element.PersonalEmail,
          phone: element.Telephone ? element.Telephone: element.Mobilephone,
          company: element.Company ? element.Company : 'Unknown',
          title: element.Title ? element.Title : 'Unknown',
          count: element.TotalStats,
          rowUrl: `${config.API_URL}/UI/#view/${element.AssigneeResourceID}`
        });
      });
      setTableData(data);
    }
    fetchTopRiskyAssigneeByMemberFunction();
  }, []);

  return (
    <InformationTableUpdated
      header={{
        title: 'Top Risky Assignees by # of Members',
      }}
      tableData={tableData}
      style={{ marginLeft: '10px', minWidth: '500px' }}
    />
  );
};

const InfoTables = () => {
  return (
    <Grid container spacing={1} display="flex" flexDirection="row">
      <Grid
        container
        md={12}
        spacing={1}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Grid item sm={10} md={12} lg={6}>
          <TopPeopleByHighRiskFuntion />
        </Grid>

        <Grid item sm={10} md={12} lg={6}>
          <TopPeopleByHighRiskMembership />
        </Grid>
      </Grid>

      <Grid
        sm={10}
        md={12}
        container
        spacing={1}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Grid item sm={10} md={12} lg={6}>
          <TopRiskyAssignees />
        </Grid>

        <Grid item sm={10} md={12} lg={6}>
          <TopPeopleByHighAzLocalRisks />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoTables;
