import { Route, Switch } from 'react-router-dom';
import {
  NotFoundPage,
  ForbiddenPage,
  SessionExpiredPage,
  MaintenancePage,
} from 'packages/eid-ui';
import { useTranslation } from 'react-i18next';
import RiskAnalyticsApp from './Risk';

const AppRoutes = () => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route
        exact
        path="/forbidden"
        component={() => (
          <ForbiddenPage
            description={'Forbidden'}
            linkText={`HomePage`}
            errorCode={'403'}
          />
        )}
      />
      <Route
        exact
        path="/notfound"
        component={() => (
          <NotFoundPage
            description={`Page Not Found`}
            linkText={`Back tos Home Page`}
            errorCode={'404'}
          />
        )}
      />
      <Route
        exact
        path="/sessionExpired"
        component={() => (
          <SessionExpiredPage
            linkText={`Re Login`}
            title={`SessionExpired`}
            description={`Session Expired. Please Login again`}
          />
        )}
      />
      <Route
        exact
        path="/underMaintenance"
        component={() => (
          <MaintenancePage
            title={t`Under Maintenance`}
            description={`Under Maintenance, Check back later`}
            linkText={`Back To Home Page`}
          />
        )}
      />
      <RiskAnalyticsApp />
    </Switch>
  );
};

export default AppRoutes;
