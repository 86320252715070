import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Header, Dropdown } from 'packages/eid-ui';
import { Icon } from 'packages/eid-icons';
import { useAuthState } from 'packages/core';
import { useIsSmallScreen } from 'packages/core';

const AccountDropdown = ({ imageUrl, friendlyName }) => {
  const [{ displayName }] = useAuthState();
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const isSmallScreen = useIsSmallScreen();

  const content = (
    <span>
      <Dropdown.Item
        onClick={() => {
          history.push('/signout');
          setOpen((prev) => !prev);
        }}
      >
        <Icon name="LogOut" color="#919193" />
        Sign Out
      </Dropdown.Item>
    </span>
  );

  const mobile = (
    <Header.AccountDropdown
      imageUrl={imageUrl}
      anchorPosition={{ right: '-32px' }}
      showCone={false}
      open={open}
      handleOpen={setOpen}
      friendlyName={friendlyName}
      style={{ marginRight: '10px' }}
    >
      {content}
    </Header.AccountDropdown>
  );

  const desktop = (
    <Header.AccountDropdown
      imageUrl={imageUrl}
      displayName={displayName}
      open={open}
      handleOpen={setOpen}
      friendlyName={friendlyName}
      style={{ marginRight: '30px' }}
    >
      {content}
    </Header.AccountDropdown>
  );
  return isSmallScreen ? mobile : desktop;
};

export default AccountDropdown;
