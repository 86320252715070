import React from 'react';
import CardStat from '../../components/Charts/Cards/CardStat.js';
import config from 'config';
import { Grid } from '@material-ui/core';

const TopOfMind = () => {
  return (
    <Grid
      container
      spacing={1}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Grid item sm={6} md={6} lg={3}>
        <CardStat
          key="1"
          config={{ statID: 12 }}
          url={`${config.API_URL}/ui/#/Reports/AccountPasswordNeverExpires?popupNav=true`}
        />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat
          key="2"
          config={{ statID: 13 }}
          url={`${config.API_URL}/ui/#/Reports/AccountADNeverLoggedIn?popupNav=true`}
        />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat key="3" config={{ statID: 16 }} />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat key="4" config={{ statID: 139 }} />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat
          key="5"
          config={{ statID: 140 }}
          url={`${config.API_URL}/ui/#/Reports/AccountOwnedDeletedPerson?popupNav=true`}
        />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat
          key="6"
          config={{ statID: 141 }}
          url={`${config.API_URL}/ui/#/Reports/PersonInvalidManager?popupNav=true`}
        />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat
          key="7"
          config={{ statID: 142 }}
          url={`${config.API_URL}/ui/#/Reports/PersonInvalidManager?popupNav=true`}
        />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat key="8" config={{ statID: 607 }} />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat key="9" config={{ statID: 608 }} />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat key="10" config={{ statID: 609 }} />
      </Grid>

      <Grid item sm={6} md={6} lg={3}>
        <CardStat key="11" config={{ statID: 610 }} />
      </Grid>
      <Grid item sm={6} md={6} lg={3}></Grid>
    </Grid>
  );
};

export default TopOfMind;
