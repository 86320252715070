import React from 'react';
import CardGauge from '../Charts/Cards/CardGauge';
import { Grid } from '@material-ui/core';

const GaugeCharts = () => {
  let contentWrapperWidth = '100%';

  const contentWrapper = {
    width: contentWrapperWidth,
    maxWidth: '115vw',
    display: 'flex',
    flexWrap: 'wrap',
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Grid item sm={8} md={6} lg={4}>
          <CardGauge key="652" id="652" config={{ statID: 652 }} />
        </Grid>
        <Grid item sm={8} md={6} lg={4}>
          <CardGauge key="653" id="653" config={{ statID: 653 }} />
        </Grid>

        <Grid item sm={8} md={6} lg={4}>
          <CardGauge key="648" id="648" config={{ statID: 648 }} />
        </Grid>
      </Grid>
    </>
  );
};

export default GaugeCharts;
