import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar, Tooltip } from 'packages/eid-ui';
import { Icon } from 'packages/eid-icons';
import { useTranslation } from 'react-i18next';
import config from 'config';

const getFullImageUrl = (personGuid: any) => {
  return `${config.API_URL}/UI/Common/Person/Image?personGUID=${personGuid}&size=Thumb`;
};
export interface IPersonDetailsProps {
  avatarSize?: any;
  person: {
    personGuid: string;
    friendlyName: string;
    email: string;
    telephone: string;
    title: string;
    company: string;
  };
}

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      margin: '5px',
      alignItems: 'center',
    },
    name: {
      fontSize: '14px',
      color: '#000',
      lineHeight: '18px',
      fontWeight: 'bold',
    },

    avatarContainer: {
      marginRight: '10px',
      '& >div': {
        height: '30px',
        width: '30px',
      },
    },
    iconsContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '5px',
      '& >*': {
        marginRight: '10px',
      },
      '& >svg': {
        margin: '0px 4px',
        height: '14px',
        width: '14px',
      },

      '& .tags': {
        height: '20px',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '5px',
        alignItems: 'center',
        border: '1px solid #307fc1',
        background: '#e0ecf6',
        // textAlign: 'center',

        '& >p': {
          fontSize: '10px',
          marginTop: '0px',
          marginBottom: '0px',
          marginRight: '10px',
          marginLeft: '5px',
          color: '#307fc1',
        },
        '& >svg': {
          fill: '#307fc1',
          marginLeft: '10px',
          height: '14px',
          width: '14px',
        },
      },
    },
  }),
);

export const PersonDetails: FC<IPersonDetailsProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { avatarSize, person } = props;

  return (
    <Box className={classes.root}>
      {person.personGuid && (
        <Box className={classes.avatarContainer}>
          <Avatar
            style={{ width: '50px', height: '50px', border: '3px solid #fff', 'box-shadow': '-2px 0 3px 0 rgba(0, 0, 0, 0.1)' }}
            size={avatarSize}
            src={getFullImageUrl(person.personGuid)}
          />
        </Box>
      )}

      <Box>
        {person.friendlyName && (
          <Typography className={classes.name}>
            {person.friendlyName}
          </Typography>
        )}

        <Box className={classes.iconsContainer}>
          <Box className={'tags'}>
            <Icon name="SeoTag" />
            <p>{person.title}</p>
          </Box>
          <Box className={'tags'}>
            <Icon name="Company" />
            <p>{person.company}</p>
          </Box>

          {person.email ? (
            <a href={`mailto:${person.email}`}>
              <Tooltip title={`mailto:${person.email}`} interactive>
                <div>
                  <Icon name="EmailRound" />
                </div>
              </Tooltip>
            </a>
          ) : (
            <div>
              <Icon name="EmailRound" color="#B4B4B4" />
            </div>
          )}
          {person.telephone ? (
            <a href={`tel:${person.telephone}`}>
              <Tooltip title={person.telephone} interactive>
                <div>
                  <Icon name="PhoneRound" />
                </div>
              </Tooltip>
            </a>
          ) : (
            <div>
              <Icon name="PhoneRound" color="#B4B4B4" />
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};
